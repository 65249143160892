class Rest {
    _axios = require('axios');
    _baseURL = process.env.REACT_APP_API_URL;
    _contentType = 'application/json';
    _credentials = {
        agent: '',
        user: '',
        token: ''
    };
    _response = '';
    _error = false;
    _debug = true;
    _external = false;
    _callback = null;
    _callbackProgress = null;

    init() {
        this._credentials.agent = "";
        this._credentials.user = "";
        this._credentials.token = "";
    }

    sendQuery(method, url, data = {}, callback = null, callbackProgress = null) {
        this._callback = callback;
        this._callbackProgress = callbackProgress;

        this.init();

        let URL = "";
        let headers = {
            "Content-Type": this._contentType
        };

        if(!this._external) {
            if(this._credentials.agent !== "")
                headers["X-Agent"] = this._credentials.agent;

            if(this._credentials.user !== "")
                headers["X-User"] = this._credentials.user;

            if(this._credentials.token !== "")
                headers["Authorization"] = "Bearer " + this._credentials.token;

            URL = this._baseURL + url;
        }
        else
            URL = url;

        if(this._debug)
            console.log("REST QUERY", URL, method, headers, data);

        switch (method) {
            case "GET": this.get(URL, headers); break;
            case "POST": this.post(URL, headers, data); break;
            case "PUT": this.put(URL, headers, data); break;
            case "PATCH": this.patch(URL, headers, data); break;
            case "DELETE": this.delete(URL, headers); break;
            default: console.log("Method undefined...!"); break;
        }
    }

    get (URL, headers) {
        this._axios.get(
            URL,
            {
                headers: headers
            }
        ).then(response => {
            this.then(response);
        })
        .catch(error => {
            this.catch(error);
        });
    }
    post (URL, headers, data) {
        this._axios.post(
            URL,
            data,
            {
                headers: headers,
                onUploadProgress: function( progressEvent ) {
                    if (this._callbackProgress !== null)
                        this._callbackProgress(parseInt(Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )));
                }.bind(this)
            }
        ).then(response => {
            this.then(response);
        })
        .catch(error => {
            this.catch(error);
        });
    }
    put (URL, headers, data) {
        this._axios.put(
            URL,
            data,
            {
                headers: headers
            }
        ).then(response => {
            this.then(response);
        })
        .catch(error => {
            this.catch(error);
        });
    }
    patch (URL, headers, data) {
        this._axios.patch(
            URL,
            data,
            {
                headers: headers
            }
        ).then(response => {
            this.then(response);
        })
        .catch(error => {
            this.catch(error);
        });
    }
    delete (URL, headers) {
        this._axios.delete(
            URL,
            {
                headers: headers
            }
        ).then(response => {
            this.then(response);
        })
        .catch(error => {
            this.catch(error);
        });
    }

    then (response) {
        this._response = response;
        if(this._debug) console.log("RESPONSE", response);

        if(this._callback !== null)
            this._callback(this._response, this._error, response.status);
    }
    catch (error) {
        this._error = error;

        if(this._debug)
            console.log("ERROR TEMP", error);

        if(this._debug && error.response !== undefined) {
            if(error.response.status !== undefined)
                console.log("ERROR_STATUS", error.response.status);

            if(error.response.data !== undefined)
                console.log("ERROR_MESSAGE", error.response.data.message);
        }

        console.log("error catched", error);
    }
}

export default Rest;

