import Rest from "../../class/tool/Rest";
import Key from "./Key";

class KeyController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "load";

    show() {
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL,
            {},
            this.handleShow);
    }
    handleShow = (response, error) => {
        const object = !error ? new Key(response.data) : null;
        this._callback(object, error);
    }
}

export default KeyController;
