import React, {useEffect, useState} from 'react'
import KeyController from "./stories/Keys/KeyController"

const Keys = () => {
    const [nbRequestsPerSecond, setNbRequestsPerSecond] = useState(0)
    let refreshKey;

    const returnFetchData = (object, error) => {
        if(!error)
            setNbRequestsPerSecond(object.nbRequestsPerSecond)
    }
    const fetchData = () => {
        const controller = new KeyController()
        controller._callback = returnFetchData
        controller.show()
    }
    const clearIntervals = () => {
        if (refreshKey !== null)
            clearTimeout(refreshKey)
    }

    useEffect(() => {
        refreshKey = setInterval(() => {
            fetchData()
        }, 5000)

        return() => {
            clearIntervals()
        }
    }, [])

    return (
        <p className="keys">{ nbRequestsPerSecond }<span>r / s</span></p>
    )
}

export default Keys
