import React, {Component} from 'react';
import moment from "moment";
import NumberFormat from "react-number-format";
moment.locale('fr')

class ItemRequest extends Component {
    render() {
        const query = this.props.query;
        const date = moment(query.date).format('DD/MM/YYYY HH:mm:ss');
        const uri = query.hasOwnProperty('incoming_request') ? query.incoming_request.uri : query.uri;
        var action = "-";
        var client = query.getUser();

        if(uri != null) {
            const match = uri.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
            action = (match ? match[5] : "-");
        }

        return (
            <tr key={ query.id } className={ !query.visible ? "hide" : "" } onClick={() => {this.props.handleClick(query.id)}}>
                <td className={"center status " + (query.expectedCode.indexOf(query.code) !== -1 ? "ok" : "nok") }>{ query.code }</td>
                <td className="center method">{ query.method }</td>
                <td className="left action">{ action }</td>
                <td className="left client">{ client }</td>
                <td className="right date">{ date }</td>
                <td className="right runtime"><NumberFormat value={ query.runtime * 100 } decimalScale={3} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} /></td>
                <td className="right ip">{ query.ip }</td>
            </tr>
        );
    }
}

export default ItemRequest;
