import React from 'react';
import Site from "./Site";

import './App.css';

function App() {
    return (
        <Site />
    );
}

export default App;
