import React from 'react'
import Filter from "./Filter"
import Menu from "./Menu"
import Keys from "./Keys"

const Head = props => {
    const {inputText, handleSearch, handleFilter, selected, nbErrors} = props

    return (
        <div className="head">
            <Filter inputText={inputText} handleSearch={handleSearch} />
            <Menu handleFilter={ handleFilter } selected={ selected } nbErrors={ nbErrors } />
            <Keys />
        </div>
    )
}

export default Head
