class IncomingRequest {
    id = 0;
    created_at = "";
    updated_at = "";
    date = "";
    ip = "";
    user = "";
    client = "";
    method = "";
    uri = "";
    fullUri = "";
    params = "";
    result = "";
    code = "";
    expectedCode = "";
    runtime = "";
    store_id = null;
    storeName = null;
    licenseNumber = null;
    licenseName = null;
    email = null;
    gateway = "";
    unexpected_response = {};
    visible = true;

    constructor(datas = {}) {
        this.setAttributes(datas);
    }

    setAttributes(datas) {
        var keys = Object.keys(this);
        var key = "";

        for(var i in keys) {
            key = keys[i];

            if (datas[key] !== undefined) {
                let exceptKeys = []; // except keys

                if (!exceptKeys.includes(key))
                    Reflect.set(this, key, datas[key]);
            }
        }
    }

    getUser() {
        var client = "";

        switch (this.gateway) {
            case "pos":
                client = this.storeName + " - " + this.licenseName + " (" + this.licenseNumber + ")";
                break;
            case "public":
                client = this.storeName + " - " + this.email.toLowerCase();
                break;
            case "hivy":
                client = this.storeName + " - HIVY";
                break;
            default: break;
        }

        return client;
    }
}

export default IncomingRequest;
