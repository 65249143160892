class Key {
    nbRequestsPerSecond = 0;

    constructor(data = 0) {
        this.setAttributes(data);
    }

    setAttributes(data) {
        this.nbRequestsPerSecond = data !== undefined ? data : 0;
    }
}

export default Key;
