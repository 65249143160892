import React from "react";
import moment from "moment";
import NumberFormat from "react-number-format";

function Preview (props) {
    const { query, handleClose } = props;
    console.log("QUERY", query);

    const BuildParams = () => {
        return query.params !== "" ? JSON.stringify(JSON.parse(query.params), null, 2) : "Aucun paramètre";
    }

    const BuildResult = () => {
        return query.result !== "" ? JSON.stringify(JSON.parse(query.result), null, 2) : "Aucun retour";
    }

    const BuildError = () => {
        return query.unexpected_response.message;
    }

    /*`
    runtime: 0.323254
     */

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox preview" onClick={handleClose}>
                    <div className="wrap">
                        <p className={"code " + (query.expectedCode.indexOf(query.code) !== -1 ? "ok" : "nok")}>{ query.code }</p>
                        <ul>
                            <li><span>Methode :</span><strong>{ query.method }</strong></li>
                            <li><span>URI :</span><strong>{ query.fullUri }</strong></li>
                            <li><span>Gateway :</span><strong>{ query.gateway }</strong></li>
                            <li><span>IP :</span><strong>{ query.ip }</strong></li>
                            <li><span>Source :</span><strong>{ query.user }</strong></li>
                            <li><span>Utilisateur :</span><strong>{ query.getUser() }</strong></li>
                            <li><span>Date :</span><strong>{ moment(query.date).format('DD/MM/YY HH:mm:ss') }</strong></li>
                            <li><span>Temps d'exécution :</span><strong><NumberFormat value={ query.runtime * 100 } decimalScale={3} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />ms</strong></li>
                        </ul>
                        <pre className="codeJson">{ BuildParams() }</pre>
                        {
                            query.unexpected_response !== null
                                ? <pre className="codeJson">{ BuildError() }</pre>
                                : <pre className="codeJson">{ BuildResult() }</pre>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Preview;
