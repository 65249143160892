import Rest from "../../class/tool/Rest";
import IncomingRequest from "./IncomingRequest";

class IncomingRequestController {
    _rest = new Rest();
    _callback = null;
    _baseURL = "requests";

    index(lastID = 0) {
        this._rest.sendQuery(
            'GET',
            '/' + this._baseURL + (lastID > 0 ? '?after=' + lastID : ''),
            {},
            this.handleIndex);
    }
    handleIndex = (response, error) => {
        let listObjects = [];

        if(response.data === undefined) {
            this._callback(listObjects, true);
            return;
        }

        const data = response.data;

        for(var item in data)
            listObjects.push( new IncomingRequest(data[item]));

        this._callback(listObjects, error);
    }
}

export default IncomingRequestController;
