import React from 'react';

const Menu = props => {
    const {selected, nbErrors, handleFilter} = props;

    return (
        <div className="menu">
            <p className={ "item" + (selected.indexOf("GET") >= 0 ? " selected" : "") } onClick={ () => { handleFilter('GET') } }>GET</p>
            <p className={ "item" + (selected.indexOf("POST") >= 0 ? " selected" : "") } onClick={ () => { handleFilter('POST') } }>POST</p>
            <p className={ "item" + (selected.indexOf("PUT") >= 0 ? " selected" : "") } onClick={ () => { handleFilter('PUT') } }>PUT</p>
            <p className={ "item" + (selected.indexOf("PATCH") >= 0 ? " selected" : "") } onClick={ () => { handleFilter('PATCH') } }>PATCH</p>
            <p className={ "item" + (selected.indexOf("DELETE") >= 0 ? " selected" : "") } onClick={ () => { handleFilter('DELETE') } }>DELETE</p>
            <p className={ "item error" + (selected.indexOf("ERROR") >= 0 ? " selected" : "") } onClick={ () => { handleFilter('ERROR') } }>Erreurs : { nbErrors }</p>
        </div>
    );
}

export default Menu;
