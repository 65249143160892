import React from 'react';

const Filter = props => {
    const {inputText, handleSearch} = props;


    // Render

    return (
        <div className="filter">
            <input type="text" className={"inputText " + (inputText.length > 1 ? "on" : "")} value={inputText} placeholder="Rechercher..." onChange={handleSearch} />
        </div>
    );
}

export default Filter;
