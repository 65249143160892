import React, {useEffect, useState} from 'react'
import {animateScroll} from "react-scroll"
import moment from "moment"
import ItemRequest from "./ItemRequest"
import IncomingRequestController from "./stories/IncomingRequests/IncomingRequestController"
import Preview from "./Preview"
moment.locale('fr')

const ListAllQueries = props => {
    const { inputText, selected, handleSetNbErrors } = props
    const [ listAll, setListAll ] = useState([])
    const [ listToDisplay, setListToDisplay ] = useState([])
    const [ querySelect, setQuerySelect ] = useState(null)

    let lastID = 0

    const returnFetchData = (list, error) => {
        if(!error) {
            if(list.length > 0 && list[(list.length - 1)].id > lastID)
                lastID = list.length > 0 ? list[(list.length - 1)].id : 0

            setListAll((listAll) => listAll.slice().concat(list))
        }
    }
    const fetchData = () => {
        const controller = new IncomingRequestController()
        controller._callback = returnFetchData
        controller.index(lastID)
    }

    const majNbErrors = listQueries => {
        let newNbErrors = 0
        let query

        for (let index in listQueries) {
            query = listQueries[index]

            if (query.expectedCode.indexOf(query.code) === -1)
                newNbErrors++
        }

        handleSetNbErrors(newNbErrors)
    }
    const checkInputTextInQuery = (textCompare, query) => {
        if (
            (query.storeName !== null && query.storeName.toLowerCase().includes(textCompare))
            || (query.licenseName !== null && query.licenseName.toLowerCase().includes(textCompare))
            || (query.licenseNumber !== null && query.licenseNumber.toLowerCase().includes(textCompare))
            || (query.email !== null && query.email.toLowerCase().includes(textCompare))
            || (query.gateway === "hivy" && "HIVY".includes(textCompare))
        ) return true

        return false
    }
    const filteringList = () => {
        var list = listAll.slice()
        var listToSet = []
        var query
        var pushed = false
        var textCompare = inputText.toLowerCase()

        for(var index in list) {
            query = list[index]
            pushed = false

            if (selected.length === 1 && selected.indexOf("ERROR") >= 0) { // filtering only errors
                if (query.expectedCode.indexOf(query.code) === -1) {
                    if (textCompare.length > 1) {
                        if (checkInputTextInQuery(textCompare, query)) {
                            listToSet.push(query)
                            pushed = true
                        }
                    }
                    else {
                        listToSet.push(query)
                        pushed = true
                    }
                }
            }
            else if (selected.length > 1 && selected.indexOf("ERROR") >= 0) { // filtering method and errors
                if (selected.indexOf(query.method) >= 0 && query.expectedCode.indexOf(query.code) === -1) {
                    if (textCompare.length > 1) {
                        if (checkInputTextInQuery(textCompare, query)) {
                            listToSet.push(query)
                            pushed = true
                        }
                    }
                    else {
                        listToSet.push(query)
                        pushed = true
                    }
                }
            }
            else if (selected.length > 0) { // filtering only methods without errors
                if (selected.indexOf(query.method) >= 0) {
                    if (textCompare.length > 1) {
                        if (checkInputTextInQuery(textCompare, query)) {
                            listToSet.push(query)
                            pushed = true
                        }
                    }
                    else {
                        listToSet.push(query)
                        pushed = true
                    }
                }
            }
            else { // filtering only input text
                if (textCompare.length > 1) {
                    if (checkInputTextInQuery(textCompare, query)) {
                        listToSet.push(query)
                        pushed = true
                    }
                }
                else {
                    listToSet.push(query)
                    pushed = true
                }
            }

            if (!pushed && selected.length === 0 && textCompare.length < 2)
                listToSet.push(query)
        }

        majNbErrors(listToSet)
        setListToDisplay(listToSet)
    }

    const scrollToBottom = () => {
        animateScroll.scrollToBottom({
            containerId: "listAllQueries"
        })
    }

    const getQueryObjectFromId = id => {
        const list = listAll.slice()

        for (var index in list)
            if (list[index].id === id)
                return list[index]

        return null
    }

    const handleClickOnRow = id => {
        setQuerySelect(getQueryObjectFromId(id))
    }
    const handleClosePreview = () => {
        setQuerySelect(null)
    }


    // Init

    useEffect(() => {
        fetchData()

        const refreshInterval = setInterval(() => {
            fetchData()
        }, 5000)

        return () => {
            clearInterval(refreshInterval)
        };
    }, [])
    useEffect(() => {
        filteringList()
    }, [listAll, inputText, selected])
    useEffect(() => {
        scrollToBottom()
    }, [listToDisplay])

    return (
        <div id="listAllQueries" className="wrap">
            {
                querySelect !== null
                && <Preview query={querySelect} handleClose={handleClosePreview} />
            }
            <table className="list">
                <tbody>
                {
                    listToDisplay.map(query => (
                        <ItemRequest key={query.id} query={query} handleClick={handleClickOnRow} />
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}

export default ListAllQueries
