import React, {useEffect, useState} from 'react'
import Head from "./Head"
import ListAllQueries from "./ListAllQueries"

const Site = () => {
    const [selected, setSelected] = useState([])
    const [inputText, setInputText] = useState("")
    const [nbErrors, setNbErrors] = useState(0)

    const handleSetNbErrors = val => {
        setNbErrors(val)
    }
    const handleSearch = event => {
        setInputText(event.currentTarget.value)
    }
    const filter = type => {
        let selectedTemp = selected.slice()
        let index = selectedTemp.indexOf(type)

        if (index >= 0) selectedTemp.splice(index, 1)
        else selectedTemp.push(type)

        setSelected(selectedTemp)
    }

    useEffect(() => {
        document.title = "SkyTill API - Logs"
    }, [])

    return (
        <div className="wrapper">
            <Head inputText={inputText} handleSearch={handleSearch} handleFilter={ filter } selected={ selected } nbErrors={ nbErrors } />
            <ListAllQueries inputText={inputText} selected={selected} handleSetNbErrors={handleSetNbErrors} />
        </div>
    )
}

export default Site
